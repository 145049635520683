import React, { useState, useEffect } from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import Social from './social'

import Wrlogo from '../../assets/logo.svg'

import * as headerStyles from './header.module.scss'
import '../styles/hamburgers.css'

const Logo = ({ color }) => (
    <div className={headerStyles.mainLogoStyle}>
        <Wrlogo fill={color} className={headerStyles.defaultLogo} />
        <Wrlogo fill={color} className={headerStyles.hoverLogo} />
    </div>
)

const Header = (props) => {
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                    description
                }
            }
        }
    `)

    const [navbarDrawn, toggleNavbar] = useState(false)

    const backgroundColor = props.backgroundColor || 'white'
    const color = props.color || '#262626'

    const toggleClick = () => {
        toggleNavbar(!navbarDrawn)
    }

    const closeNavBar = () => {
        toggleNavbar(false)
    }

    const rolldownMenuState = navbarDrawn
        ? headerStyles.rolldownMenuDrawn
        : headerStyles.rolldownMenu

    const titleColors = navbarDrawn ? 'white' : color

    return (
        <header
            style={{
                background: `${backgroundColor}`,
                color: `${titleColors}`,
            }}
            className={headerStyles.header}
        >
            <Link
                style={{ color: `${titleColors}` }}
                className={headerStyles.title}
                onClick={closeNavBar}
                to="/"
            >
                <Logo color={titleColors} />
            </Link>
            <nav>
                <div className={headerStyles.navList}>
                    <Link
                        style={{ color: `${titleColors}` }}
                        className={headerStyles.navItem}
                        activeClassName={headerStyles.activeNavItem}
                        onClick={closeNavBar}
                        to="/work/"
                    >
                        Work
                    </Link>
                    <Link
                        style={{ color: `${titleColors}` }}
                        className={headerStyles.navItem}
                        activeClassName={headerStyles.activeNavItem}
                        onClick={closeNavBar}
                        to="/about/"
                    >
                        About
                    </Link>
                    <Link
                        style={{ color: `${titleColors}` }}
                        className={headerStyles.navItem}
                        activeClassName={headerStyles.activeNavItem}
                        onClick={closeNavBar}
                        to="/contact/"
                    >
                        Contact
                    </Link>
                    <Link
                        style={{ color: `${titleColors}` }}
                        className={headerStyles.navItem}
                        activeClassName={headerStyles.activeNavItem}
                        onClick={closeNavBar}
                        to="/hiring"
                    >
                        We&apos;re hiring
                    </Link>
                    <div className={headerStyles.navItemSticky}>
                        <button
                            style={{ outline: 'none' }}
                            onClick={toggleClick}
                            className={
                                navbarDrawn
                                    ? 'hamburger hamburger--collapse-r is-active'
                                    : 'hamburger hamburger--collapse-r'
                            }
                            type="button"
                        >
                            <span className="hamburger-box">
                                <span
                                    style={
                                        navbarDrawn
                                            ? { backgroundColor: 'white' }
                                            : {
                                                  backgroundColor: `${props.hamburgerColor}`,
                                              }
                                    }
                                    className="hamburger-inner"
                                ></span>
                            </span>
                        </button>
                    </div>
                </div>
                {/* Main page dropdown menu */}
                <div className={headerStyles.topNavCarrier}>
                    <div className={rolldownMenuState}>
                        <Link
                            style={{ color: `${titleColors}` }}
                            className={headerStyles.title}
                            onClick={closeNavBar}
                            to="/"
                        >
                            <Logo color={titleColors} />
                        </Link>
                        <div className={headerStyles.rolldownMenuBox}>
                            <Link
                                style={{ color: 'white' }}
                                className={headerStyles.navItem}
                                activeClassName={headerStyles.activeNavItem}
                                onClick={closeNavBar}
                                to="/work/"
                            >
                                Work.
                            </Link>
                            <Link
                                style={{ color: 'white' }}
                                className={headerStyles.navItem}
                                activeClassName={headerStyles.activeNavItem}
                                onClick={closeNavBar}
                                to="/about/"
                            >
                                About.
                            </Link>
                            <Link
                                style={{ color: 'white' }}
                                className={headerStyles.navItem}
                                activeClassName={headerStyles.activeNavItem}
                                onClick={closeNavBar}
                                to="/contact/"
                            >
                                Contact.
                            </Link>
                            <Link
                                style={{ color: 'white' }}
                                className={headerStyles.navItem}
                                activeClassName={headerStyles.activeNavItem}
                                onClick={closeNavBar}
                                to="/hiring"
                            >
                                We&apos;re hiring.
                            </Link>
                            <div className={headerStyles.headerSideLinks}>
                                <Social
                                    color={titleColors}
                                    opacity={0.3}
                                    menuItem
                                />
                                <p>
                                    ©&nbsp;
                                    {data.site.siteMetadata.description}
                                </p>
                                <Link
                                    to="/imprint"
                                    style={{ fontWeight: 'normal' }}
                                >
                                    Imprint
                                </Link>
                                <Link
                                    to="/privacy"
                                    style={{ fontWeight: 'normal' }}
                                >
                                    Privacy Policy
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </header>
    )
}

export default Header

import React from 'react'
import { Link, navigate, graphql, useStaticQuery } from 'gatsby'
import useSocialData from './custom-hooks/useSocialData'
import Logo from '../../assets/logo.svg'
import Linkedin from '../../assets/Social/Social_v2/LinkedIN.svg'
import Instagram from '../../assets/Social/Social_v2/Instagram.svg'

import * as footerStyles from './footer.module.scss'

const SocialLogo = ({ color, Component, opacity, path }) => (
    <a
        href={path}
        className={footerStyles.socialLogo}
        style={{ opacity: opacity }}
    >
        <Component fill={color} height="36px" width="36px" />
    </a>
)

const Footer = () => {
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                    description
                }
            }
        }
    `)

    const payload = useSocialData()
    const footerText = payload.allWp.edges[0].node.options.options.footerText
    const pathObject = payload.allWp.edges[0].node.options.options

    return (
        <footer className={footerStyles.footer}>
            <div className={footerStyles.topMost}>
                <div className={footerStyles.titleBox}>
                    <Logo fill="#262626" height="4em" width="6em" />
                </div>
                <div className={footerStyles.footerBox}>
                    <p>{footerText}</p>
                    <button
                        className={footerStyles.footerButton}
                        onClick={() => {
                            navigate('/contact')
                        }}
                    >
                        CONTACT
                    </button>
                </div>
            </div>
            <hr />
            <div className={footerStyles.bottom}>
                <div className={footerStyles.titleBox}>
                    <p>©{data.site.siteMetadata.description}</p>
                </div>
                <div className={footerStyles.linkBox}>
                    <div className={footerStyles.iconsContainer}>
                        <div className={footerStyles.iconBox}>
                            <SocialLogo
                                color={'#262626'}
                                Component={Linkedin}
                                opacity={0.8}
                                path={pathObject.linkedin}
                            />
                        </div>
                        <div className={footerStyles.iconBox}>
                            <SocialLogo
                                color={'#262626'}
                                Component={Instagram}
                                opacity={0.8}
                                path={pathObject.instagram}
                            />
                        </div>
                    </div>
                    <Link to="/imprint" style={{ fontWeight: 'normal' }}>
                        IMPRINT
                    </Link>
                    <Link to="/privacy" style={{ fontWeight: 'normal' }}>
                        PRIVACY POLICY
                    </Link>
                </div>
            </div>
            <div className={footerStyles.titleBoxMobile}>
                <Logo color="#262626" height="90px" width="110px" />
                <p>©{data.site.siteMetadata.description}</p>
            </div>
        </footer>
    )
}

export default Footer

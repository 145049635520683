import { useStaticQuery, graphql } from 'gatsby'

export default () => {
    const payload = useStaticQuery(
        graphql`
            query {
                allWp {
                    edges {
                        node {
                            options {
                                options {
                                    behance
                                    facebook
                                    instagram
                                    linkedin
                                    twitter
                                    footerText
                                }
                            }
                        }
                    }
                }
            }
        `
    )
    return payload
}

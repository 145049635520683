import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { ScrollProvider } from './context/ScrollContext'
import Footer from './footer'
import Header from './header'
import useScroll from './custom-hooks/useScroll'

import '../styles/index.scss'
import '../styles/fonts.scss'
import '../styles/custom.scss'

const Layout = (props) => {
    const isClient = typeof window === 'object' && typeof document === 'object'
    const luminousity = {
        DARK: 'dark',
        LIGHT: 'light',
        WHITE: 'white',
        BLACK: 'black',
    }

    const { scrollX, scrollY, scrollDirection } = useScroll()
    const [localElements, setLocalElements] = useState(null)
    const [backgroundLuminousity, setBackgroundLuminousity] = useState(
        luminousity.LIGHT
    )
    const [explicitTextColor, setExplicitTextColor] = useState(null)

    useEffect(() => {
        if (!isClient) {
            return false
        }

        const element = document.elementsFromPoint(0, 50)

        setLocalElements(element)
    }, [scrollY])

    const elementWithColor = (elements) => {
        let coloredElement = null
        if (elements) {
            elements.forEach((element) => {
                if (element.style.backgroundColor !== '') {
                    // console.log(element)
                    coloredElement = element
                }
            })
        }
        return coloredElement
    }

    const definedTextColor = (elements) => {
        let coloredElement = null
        if (elements) {
            elements.forEach((element) => {
                if (element.style.color !== '') {
                    coloredElement = element
                }
            })
        }
        return coloredElement
    }

    const luminousitySetter = (value) => {
        if (value !== backgroundLuminousity) {
            setBackgroundLuminousity(value)
        }
    }

    // console.log(`Scroll is ${scrollY}`)
    // console.log(localElements)

    const lightOrDark = (color) => {
        let r
        let g
        let b
        if (!color) {
            return
        }

        if (color === '') {
            luminousitySetter(luminousity.WHITE)
            return
        }

        if (color === 'white') {
            luminousitySetter(luminousity.WHITE)
            return
        }

        if (color === 'rgb(255, 255, 255)') {
            luminousitySetter(luminousity.WHITE)
            return
        }

        if (color === 'rgb(0,0,0)') {
            luminousitySetter(luminousity.BLACK)
            return
        }

        // Check the format of the color, HEX or RGB?
        if (color.match(/^rgb/)) {
            // If HEX --> store the red, green, blue values in separate variables
            color = color.match(
                /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
            )

            r = color[1]
            g = color[2]
            b = color[3]
        } else {
            // If RGB --> Convert it to HEX: http://gist.github.com/983661
            color = +(
                '0x' + color.slice(1).replace(color.length < 5 && /./g, '$&$&')
            )

            r = color >> 16
            g = (color >> 8) & 255
            b = color & 255
        }

        // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
        const hsp = Math.sqrt(
            0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b)
        )

        // Using the HSP value, determine whether the color is light or dark
        if (hsp > 127.5) {
            // console.log('light')
            luminousitySetter(luminousity.LIGHT)
            return
        }
        // console.log('dark')
        luminousitySetter(luminousity.DARK)
    }

    const setBurgerColor = () => {
        if (explicitTextColor) {
            return explicitTextColor
        }
        if (backgroundLuminousity === luminousity.WHITE) {
            return '#262626'
        }
        if (backgroundLuminousity === luminousity.BLACK) {
            return 'white'
        }
        if (backgroundLuminousity === luminousity.DARK) {
            return 'white'
        }
        return '#262626'
    }

    const textColor = definedTextColor(localElements)
    // console.log(textColor)
    if (textColor && explicitTextColor !== textColor.style.color) {
        setExplicitTextColor(textColor.style.color)
    }
    if (!textColor && explicitTextColor) {
        // console.log('couldnt find explicit text color')
        setExplicitTextColor(null)
        const coloredLayer = elementWithColor(localElements)
        if (coloredLayer) {
            lightOrDark(coloredLayer.style.backgroundColor)
        } else {
            // set it to light when there are no colored layers
            luminousitySetter(luminousity.LIGHT)
        }
    }

    return (
        <React.Fragment>
            <Header
                backgroundColor={props.backgroundColor || 'white'}
                color={props.color || '#262626'}
                hamburgerColor={setBurgerColor()}
            />
            <ScrollProvider value={scrollY}>
                <main>{props.children}</main>
            </ScrollProvider>
            {!props.noFooter ? <Footer /> : null}
        </React.Fragment>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
    noFooter: PropTypes.bool,
}

Layout.defaultProps = {
    noFooter: false,
}

export default Layout

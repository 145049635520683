// extracted by mini-css-extract-plugin
export const header = "header-module--header--_rwsu";
export const title = "header-module--title--2VjU5";
export const navList = "header-module--nav-list--1BtLb";
export const navItem = "header-module--nav-item--3weON";
export const activeNavItem = "header-module--active-nav-item--3mXQY";
export const navItemSticky = "header-module--nav-item-sticky--k3Mwy";
export const topNavCarrier = "header-module--top-nav-carrier--18mj2";
export const rolldownMenu = "header-module--rolldown-menu--nHjLw";
export const rolldownMenuDrawn = "header-module--rolldown-menu-drawn--1OSE2";
export const rolldownMenuBox = "header-module--rolldown-menu-box--1WtmM";
export const headerSideLinks = "header-module--header-side-links--3ReMf";
export const mainLogoStyle = "header-module--main-logo-style--34VT3";
export const defaultLogo = "header-module--default-logo--2RkZL";
export const hoverLogo = "header-module--hover-logo--TZYvo";
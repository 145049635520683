// extracted by mini-css-extract-plugin
export const footer = "footer-module--footer--3L5Rb";
export const topMost = "footer-module--top-most--3dHtM";
export const bottom = "footer-module--bottom--37eGU";
export const iconsContainer = "footer-module--icons-container--27ViG";
export const iconBox = "footer-module--icon-box--3NMcL";
export const socialLogo = "footer-module--social-logo--36oXg";
export const footerBox = "footer-module--footer-box--2zngc";
export const footerButton = "footer-module--footer-button--15wCH";
export const titleBox = "footer-module--title-box--2x2vC";
export const titleBoxMobile = "footer-module--title-box-mobile--1te-q";
export const linkBox = "footer-module--linkBox--34d3f";
import React from 'react'
import useSocialData from './custom-hooks/useSocialData'
import Linkedin from '../../assets/Social/linkedin.svg'
import Instagram from '../../assets/Social/instagram.svg'

import * as socialStyles from './social.module.scss'

const Logo = ({ color, Component, opacity, path }) => (
    <a href={path} className={socialStyles.icon} style={{ opacity: opacity }}>
        <Component fill={color} height="2em" width="2em" />
    </a>
)

const Social = (props) => {
    const payload = useSocialData()
    let pathObject = '.'
    try {
        pathObject = payload.allWp.edges[0].node.options.options
    } catch (e) {
        pathObject = '.'
    }
    return (
        <div
            className={
                props.menuItem
                    ? socialStyles.iconContainerSmall
                    : socialStyles.iconContainer
            }
            style={{ background: props.backgroundColor, color: props.color }}
        >
            <div className={socialStyles.iconBox}>
                <Logo
                    color={props.color}
                    Component={Linkedin}
                    opacity={props.opacity}
                    path={pathObject.linkedin}
                />
                <a
                    href={pathObject.linkedin}
                    style={{ color: props.color }}
                    className={socialStyles.iconName}
                >
                    LinkedIn
                </a>
            </div>
            <div className={socialStyles.iconBox}>
                <Logo
                    color={props.color}
                    Component={Instagram}
                    opacity={props.opacity}
                    path={pathObject.instagram}
                />
                <a
                    href={pathObject.instagram}
                    style={{ color: props.color }}
                    className={socialStyles.iconName}
                >
                    Instagram
                </a>
            </div>
        </div>
    )
}

Social.defaultProps = {
    color: 'white',
    backgroundColor: 'black',
    menuItem: false,
    opacity: 1,
}

export default Social
